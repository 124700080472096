@use "../../../config";
.table-wrapper {
  display: flex;
  justify-content: center;
}

.table {
  width: 100%;
}

.table-th {
  background-color: config.$status-notification-500;
  border: 1px solid #e0e0e0;

  &__text {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    width: fit-content;
    color: #fff;
  }

  &__row > th {
    padding: 10px 20px;
    width: fit-content;
  }
}

.table-bd {

  &__text, &__actions, &__categories, &__img {
    padding: 10px 20px;
  }

  &__img {
    width: 88px;
    object-fit: cover;
  }

  &__button-change {
    background-color: config.$status-success-500;
    padding: 5px;
    border-radius: 4px;
  }

  &__button-delete {
    background-color: config.$status-error-500;
    padding: 5px;
    border-radius: 4px;
  }

  &__button-svg {
    width: 16px;
    height: 16px;
    fill: #fff;
    stroke: #fff;
  }
}