.header-section__flyout {
  max-height: 800px;
  height: auto;
  transition: max-height 250ms;

}

.flyout__first-level {
  display: flex;
  justify-content: center;
  position: absolute;
  background-color: #ffffff;
  width: 100vw;
  top: 60px;
  left: 0;
  right: 0;
}