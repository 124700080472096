.cart__product-description {
  padding: 0 4px 0 10px;
  font-size: 14px;
  width: 100%;
}

.cart__product-link {
  display: flex;
}

.cart__product {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart__product-remove-button-icon {
  width: 24px;
  height: 24px;
}

.cart__product-price {
  padding: 3px 0 7px 0;
}

.cart__product-color, .cart__product-size {
  margin-bottom: 3px;
  font-size: 12px;
}