.sniffle-product-configuration {
  width: 50%;
  height: 100%;
  margin-top: 10rem;
  display: flex;
  justify-content: center;
}

.product-actions {
  display: flex;
  justify-content: space-around;
}

.product-configuration {
  width: 592px;
}

.product-further-details {
  margin-bottom: 20px;
}

.product-description {
  margin-bottom: 20px;
}

.product-description__name h1{
  font-size: 32px;
  font-weight: 400;
}

.product-description__price {
  font-size: 14px;
  font-weight: 300;
  margin-top: 10px;
}