.sniffle-product-list {
  display: grid;
  grid-template-columns: repeat(4,minmax(0,1fr));
  gap: 4px;
}

.product-body {
  position: relative;
}

.product-visual-img {
  object-fit: cover;
  aspect-ratio: 4/5;
  width: 100%;
}

.product-favorite-button {
  position: absolute;
  top: 22px;
  right: 22px;
  width: 16px;
  height: 16px;

  &-svg {
    width: 16px;
    height: 16px;
  }
}

.product-cart-action {
  position: absolute;
  bottom: 22px;
  right: 22px;
  width: 16px;
  height: 16px;

  &-svg {
    width: 16px;
    height: 16px;
    fill:none;
    stroke: #000;
  }
}

.product-container {
  cursor: pointer;
}

.product-name {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 8px;
}

.product-price {
  font-size: 12px;
  margin-bottom: 20px;
}