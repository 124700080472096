.sniffle-product__image-item {
  width: calc(25vw - 30px);
  cursor: pointer;
  object-fit: contain;
}

.sniffle-product__image picture {
  transition: opacity .3s cubic-bezier(.39,.575,.565,1)
}

.product-image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}