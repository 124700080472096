.gender-card__description {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 2.5rem;
  bottom: 0;
  left: 0;
  color: #fff;

  &-title {
    margin-bottom: 10px;
    color: #fff;
    font-size: 20px;
    overflow: hidden;
    background-image: linear-gradient(#fff, #fff);
    background-position:0 100%;
    background-repeat: no-repeat;
    transition: background-size .3s cubic-bezier(.39,.575,.565,1);
    background-size: 0 1px;
  }
}

.gender-card__second:hover .gender-card__description-title,  .gender-card__first:hover .gender-card__description-title{
    background-size: 100% 1px;
}