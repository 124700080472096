.underline {
  color: #000;
  overflow: hidden;
  background-image: linear-gradient(#000, #000);
  background-position:0 100%;
  background-repeat: no-repeat;
  transition: background-size .3s cubic-bezier(.39,.575,.565,1);
  background-size: 0 1px;

  &:hover {
    background-size: 100% 1px;
  }
}