.gender-card__visual {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.gender-card__visual-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.gender-card__link:hover .gender-card__visual-item {
  transform: scale(1.05);
}