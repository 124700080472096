.carousel__nav-button-icon {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  padding: 12px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.not-available {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.02);
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.not-available > svg{
  fill: rgba(0, 0, 0, 0.5);
}

.carousel__nav-button-icon-arrow-b {
  transform: scaleX(-1);
  width: 16px;
  height: 16px;
}

.carousel__nav-button-icon-arrow {
  width: 16px;
  height: 16px;
}