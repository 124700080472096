.hero-wrapper__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: absolute;
  top: 180px;
  bottom: 0;
  width: 100%;
}