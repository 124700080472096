.sniffle-cart {
  padding: 100px 300px;
}

.cart-header__text {
  margin: 20px 0;
}

.cart-body {
  display: flex;
  justify-content: space-between;
}