.section-footer__text {
  text-align: center;
  margin: 100px 0;
  font-size: 24px;
  font-weight: 400;
}

.footer-image__item {
  width: 1920px;
  height: 680px;
  object-fit: cover;
}