.product-further-details__wrapper {
  border-bottom: 1px solid #e0e0e0;

  & summary {
    list-style: none;
    cursor: pointer;
    padding: 20px 0;
  }

  &[open] svg{
    transform: rotate(-90deg);
  }
}

.product-details__header {
  display: flex;
  justify-content: space-between;

  &-text {
    font-size: 14px;
    font-weight: 400;
  }

  &-svg {
    transition: transform .3s cubic-bezier(.39,.575,.565,1);
    transform: rotate(90deg);
    width: 14px;
  }
}

.product-details__body {
  padding-bottom: 20px;
}

.right-panel {
  display: flex;
  align-items: center;
}

.product-details__header-current {
  margin-right: 16px;
}