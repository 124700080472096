.sniffle-product__description {
  position: absolute;
  display: flex;
  align-items: flex-end;
  top: 0;
  left: 0;
  bottom: 0;
  /*padding: 1rem;*/
  width: 100%;
  overflow: hidden;
}

.sniffle-product__description-details {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  gap: 0.25rem;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  padding: 20px 30px;
  bottom: -100px;
  position: absolute;
  transition: transform ease .3s;
}

.sniffle-product__slider img {
  transition: transform ease 0.3s;
}

.carousel-list__item:hover img{
  transform: translateY(-82px);
}

.carousel-list__item:hover .sniffle-product__description-details {
  transform: translateY(-100px);
}