.profile-wrapper {
  display: none;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 18px;
  padding: 20px 20px 0 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 12px 20px 0 rgba(0, 0, 0, 0.08);
  min-width: 250px;
  width: fit-content;
}

.flyout-text {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
}

.profile-list__item {
  margin-bottom: 20px;
}

.header-icons__nav-item {
  position: relative;
}

.profile-wrapper {
  position: absolute;
  right: -50px;
  top: 30px
}