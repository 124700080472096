.product-color__img {
  width: 88px;
}

.product-color__list-item {
  position: relative;
  width: 88px;
  cursor: pointer;
}

.product-color__link:hover::after, .selected-color::after {
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #000;
}

.product-colors__current {
  margin-bottom: 8px;
}

.product-colors__current-text {
  display: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}