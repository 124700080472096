.brand-logo {
  color: yellow;

  &:hover {
    color: black;
  }
}

.header:hover {
  .brand-logo {
    color: black;
  }
}

.brand-logo {
  transition: color .4s ease,border .4s ease;
}