.profile-section {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 120px;
  height: 800px;

  &__data, &__favorites {
    width: 40%;
    border: 1px solid #e0e0e0;
    padding: 30px;
    height: fit-content;
    border-radius: 18px;
  }

  &__header {
    margin-bottom: 16px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 14px;

    &__text {

    }
  }
}

.profile-item {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 18px;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 12px 20px 0 rgba(0, 0, 0, 0.08);
}

