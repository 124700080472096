.product-sizes__list-item {
  position: relative;
  width: 88px;
  height: 88px;
  border: 1px solid #e0e0e0;
  transition: border-color .2s cubic-bezier(.39,.575,.565,1);

  &:hover {
    border-color: #000;
  }

  & input[type="radio"] {
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

.active-size {
  border-color: #000;
}

.product-sizes__list {
  border: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.8rem;
  padding: 0;
}

.product-sizes__label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.size-not-available {
  background-color: #e0e0e0;
  color: rgb(100, 100, 100);
  cursor: default;
  border: none;
}