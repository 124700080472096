.search-bar__form {
  width: 100%;
  position: absolute;
  top: -72px;
  height: 72px;
  display: flex;
  align-content: center;
  justify-content: center;
  background: white;
  color: #000;
  transition: transform ease .3s;
}

.search-bar__form[active="true"] {
  transform: translateY(72px);
}

.search-bar__input {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 500px;

  &:focus-within > label {
    top: 10px;
    left: 25px;
    font-size: 10px;
  }
}

.has-text > label {
  top: 10px;
  left: 25px;
  font-size: 10px;
}

.search-bar__visual {
  position: absolute;
  left: 13px;
  top: 28px;
}

.search-bar__lable {
  position: absolute;
  left: 40px;
  top: 29px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  cursor: text;
  background: #fff;
  transition: all .6s cubic-bezier(.5,0,0,1);
  transform-origin: top left;
}

.search-bar__field {
  width: 580px;
  height: 40px;
  padding: 0 39px;
  outline: none;
  border: .1rem solid #e0e0e0;

  &:focus {
    border: .1rem solid #000;
  }
}

.search-bar__icon {
  width: 16px;
  height: 16px;
  fill: none;
  stroke: #000;
  cursor: pointer;
}

.search-bar__close {
  display: flex;
}