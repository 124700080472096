.cart-item {
  display: flex;
  width: 750px;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 18px;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.04),0 12px 20px 0 rgba(0,0,0,.08);

  &__img {
    width: 185px;
    height: 185px;
    object-fit: cover;
    border-radius: 18px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__name-text {
    font-size: 20px;
    font-weight: 500;
  }

  &__color, &__size {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 300;
  }

  &__price {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 300;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__amount-select {
    background: transparent;
    border-radius: 18px;
    border: 1px solid #e0e0e0;
    padding: 5px 15px;
  }
}