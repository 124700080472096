.cart-flyout {
  display: none;
  position: absolute;
  top: 30px;
  background-color: #fff;
  width: 520px;
  right: -50px;
  color: #000;
  stroke: #000;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 12px 20px 0 rgba(0, 0, 0, 0.08);
  border-radius: 18px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    border-bottom: .1rem solid #e0e0e0;
  }

  &__header-text {
    font-size: 20px;
  }

  &__header-close-button {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  &__header-close-button-icon {
    width: 16px;
    height: 16px;
  }

  &__body {
    height: 230px;
    padding: 20px 30px;
    border-bottom: .1rem solid #e0e0e0;
    overflow-y: auto;
  }

  &__product-item {
   margin-bottom: 15px;
  }
}