/* Variable Font-Weight */
$font-path: "../../src/fonts";
@font-face {
  font-family: "Montserrat";
  src: url("#{$font-path}/Montserrat-VariableFont_wght.woff2") format("woff2-variations");

  font-display: swap;
  font-weight: 100 900;
}

@font-face {
  font-family: "Montserrat";
  src: url("#{$font-path}/Montserrat-Italic-VariableFont_wght.woff2") format('woff2-variations');

  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
}