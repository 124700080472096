.arrow-button {
  display: flex;
  color: #000;
  background-color: #ffffff;
  padding: 12px 24px;
  border-radius: 20px;
  transition: all .3s ease;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  border: 1px solid #000;

  & svg {
    fill: #000;
  }
}

.arrow-button > .arrow {
  width: 6px;
  height: 6px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 6px;
  transition: all .3s ease;
}

.arrow-button > .arrow::before {
  display: block;
  background-color: currentColor;
  width: 3px;
  transform-origin: bottom right;
  height: 2px;
  position: absolute;
  opacity: 0;
  bottom: calc(-2px / 2);
  transform: rotate(45deg);
  transition: all .3s ease;
  content: "";
  right: 0;
}

.arrow-button:hover > .arrow {
  transform: rotate(-45deg) translate(4px, 4px);
  border-color: #000;
}

.arrow-button:hover > .arrow::before {
  opacity: 1;
  width: 8px;
}

.arrow-button:hover {
  background-color: #000;
  color: #fff;
  border-color: #fff;

  & svg {
    fill: #fff;
  }
}

.arrow-button:hover .arrow {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.secondary-button__Svg {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}