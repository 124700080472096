/*Colors*/

/*Status / Secondary Colors*/
$status-success-500: #6CB96E; //Fern
$status-success-100: #F0F7EF; //White Smoke
$status-success-200: #CEE4C7; //Surf Crest
$status-success-300: #B1D6AB; //Coriander
$status-success-400: #92C88C; //Frog Green
$status-success-600: #477A48; //Hippie Green
$status-success-700: #223922; //Myrtle

$status-notification-500: #5F9ACE; //Blue Koi
$status-notification-100: #E3EAF7; //Lavender Mist
$status-notification-200: #C6D5ED; //Pale Aqua
$status-notification-300: #A7C1E3; //Cloudy Blue
$status-notification-400: #86ADD9; //Glacier
$status-notification-600: #416A8F; //Denim
$status-notification-700: #253B4F; //Dark Blue Grey

$status-error-500: #D15354; //Dark Coral
$status-error-100: #F6DFDB; //Peach Schnapps
$status-error-200: #EDBEB6; //Rose Fog
$status-error-300: #E39C93; //Sea Pink
$status-error-400: #DA7972; //Dirty Pink
$status-error-600: #93393A; //Sanguine Brown
$status-error-700: #522222; //Crater Brown;

$status-warning-500: #D0A25D; //Sand Brwon
$status-warning-100: #F6ECDE; //Antique White
$status-warning-200: #ECE0BD; //Tan Brown
$status-warning-300: #E3C69D; //Cashmere
$status-warning-400: #D9B47D; //Very Light Brown
$status-warning-600: #92713F; //Wood
$status-warning-700: #513F25; //Cafe Noire

/*Neutral Colors*/
$neutral-color-500: #67868A; //Steel Grey
$neutral-color-100: #FFFFFF; //White
$neutral-color-200: #E0E4E6; //Platinum
$neutral-color-300: #C2CACC; //Silver
$neutral-color-400: #A4B1B5; //Aluminium
$neutral-color-600: #283B3D; //Dark Slate Gray
$neutral-color-700: #323332; //Dune