.flyout__first-level-item {
  width: 15%;
  margin-left: 10px;
  padding: 0 48px 36px 48px;
}

.flyout__first-level-link {
  font-weight: 500;
  cursor: pointer;
/*  padding-top: 36px;
  padding-bottom: 12px;
  margin-bottom: 12px;*/
}

.flyout__second-level {
  margin-top: 12px;
}

.flyout__second-level-item {
  margin-top: 8px;
}

.flyout__first-level-link, .flyout__second-level-link {
  cursor: pointer;
  margin-bottom: 10px;
  color: #000;
  overflow: hidden;
  background-image: linear-gradient(#000, #000);
  background-position:0 100%;
  background-repeat: no-repeat;
  transition: background-size .3s cubic-bezier(.39,.575,.565,1);
  background-size: 0 1px;

  &:hover {
    background-size: 100% 1px;
  }
}