.sniffle-cart-overview {
  width: 400px;
  border: 1px solid #e0e0e0;
  border-radius: 18px;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04), 0 12px 20px 0 rgba(0, 0, 0, 0.08);
}

.cart-overview {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    padding: 10px  0 15px 0;
    border-bottom: 1px solid #e0e0e0;

    &-text {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 15px;
    flex-grow: 1;
  }

  &__price, &__actions, &__extra {
    display: flex;
    justify-content: space-between;

    &-text {
      font-size: 14px;
      font-weight: 300;
    }
  }
}