.homepage-image__item {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-wrapper__visual-link {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.homepage-image {
  height: 100vh;
}