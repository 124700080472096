* {
  box-sizing: border-box;
  margin: 0;
}

html {
  font-family: Montserrat, sans-serif;
}

ul, li {
  list-style-type: none;
  padding: 0;
}

button {
  background: transparent;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-align: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}