@use "../../../config";

.sign-background {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.sign-form {
  position: relative;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  cursor: text;
  background: #fff;
  transition: all 0.6s cubic-bezier(0.5, 0, 0, 1);
  transform-origin: top left;

  &:focus-within > label, &__has-text > label{
    top: -7px;
    left: 20px;
    font-size: 10px;
  }

  &__item {
    position: relative;
    margin-bottom: 10px;

    &:focus-within > label, &__has-text > label{
      top: -7px;
      left: 20px;
      font-size: 10px;
    }
  }
}

.sign-wrapper {
  width: 700px;
  background-color: white;
  padding: 30px;
  z-index: 100;
  border-radius: 18px;
}

.sign-form {
  margin-bottom: 30px;
}

.sign-header {
  margin-bottom: 40px;

  &__text {
    margin-bottom: 10px;
  }

  &__paragraph {
    font-size: 12px;
  }
}

.error-text {
  display: none;
  position: absolute;
  color: config.$status-error-500;
  margin-top: 5px;
}

.error {
  border-color: config.$status-error-500;
  color: config.$status-error-500;
}

.sign-in-input {
  width: 580px;
  height: 40px;
  padding: 0 20px;
  outline: none;
  border: 0.1rem solid #e0e0e0;

  &:focus {
    border: .1rem solid #000;
  }
}

.sign-in-label {
  position: absolute;
  left: 20px;
  top: 11px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  cursor: text;
  background: #fff;
  transition: all 0.6s cubic-bezier(0.5, 0, 0, 1);
  transform-origin: top left;
}

.sign-register {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.sign-form__item-input {
  width: 100%;
  height: 40px;
  padding: 0 20px;
  outline: none;
  border: 0.1rem solid #e0e0e0;
}

.sign-form__street, .sign-form__number  {
  grid-column: span 2;
}