.hero-text {
  color: white;
  margin-bottom: 1rem;
}

.hero-gender-text {
  font-size: .875rem;
  padding: .125rem .5rem;
  background-color: #fff;
  color: #000;
}

.hero-collection-text {
  font-size: 2rem;
}

.hero-collection-description {
  color: white;
}