.change-popup-wrapper {
  width: 100vw;
  height: 100vh;
  top: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.change-popup {
  background: #fff;
  padding: 30px;
  border-radius: 18px;
  width: 440px;

  &__header {
    margin-bottom: 20px;
  }
}

.change-action {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}