.organizer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .2rem;

  &__svg {
    width: 16px;
    height: 16px;
    padding: 4px;
    transform: rotate(90deg);
  }

  &:hover .organizer__svg, .organizer__active {
    transform: rotate(-90deg);
  }

  &__deactive {
    transform: rotate(90deg);
  }

  &__text {
    text-transform: uppercase;
  }
}