.productmanagement-wrapper {
  padding: 120px 200px;
}

.productmanagement-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.search-bar__product {
  display: flex;
  align-items: center;
  position: relative;

  &:focus-within > label, &__has-text {
    top: -5px;
    left: 25px;
    font-size: 10px;
  }
}

.search-bar__product-label {
  position: absolute;
  left: 40px;
  top: 13px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  cursor: text;
  background: #fff;
  transition: all 0.6s cubic-bezier(0.5, 0, 0, 1);
  transform-origin: top left;
}

.search-bar__product-visual {
  position: absolute;
  left: 13px;
  top: 13px;
}

.search-bar__product-field {
  width: 240px;
  height: 40px;
  padding: 0 39px;
  outline: none;
  border: 0.1rem solid #e0e0e0;
}