.hero-collection-button {
  color: #fff;

  &__item {
    cursor: pointer;
    /*border: 2px solid #fff;*/

    &-link {
      display: flex;
      padding: 0.5rem 0;
      padding-left: 1.5rem;
      line-height: 1rem;
    }

    &-text {
      display: flex;
      transform: translateX(0);
      transition: 0.3s all;
    }

    &-arrow {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__arrow {
    height: 16px;
    width: 16px;
    fill: #fff;
    opacity: 0;
  }

  &:hover .hero-collection-button__item-text {
    transform: translateX(-16px);

    .hero-collection-button__arrow {
      transition: .3s ease-out normal;
      opacity: 1;
    }
  }
}