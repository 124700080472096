.gender-card {

  &__first {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 100%;
    top: 0;
  }

  &__second {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    top: 0;
  }

  &__men {
    height: 100%;
    display: flex;
    &-wrapper {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }

  &__woman {
    height: 100%;
    display: flex;

    &-wrapper {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }

  &__link {
    display: block;
    position: relative;
    overflow: hidden;
  }
}