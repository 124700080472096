.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: background-color .4s ease,border .4s ease;
  background-color: transparent;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: #000;

    .header-icons__nav-item {
      transition: stroke .4s ease,border .4s ease;
      stroke: #000;
    }
  }
}

.header-icons__nav-item {
  stroke: #fff;
}

.header-section {
  display: flex;
  flex-direction: row;
  padding: 0 48px;
  width: 100%;
  height: 72px;
  justify-content: space-between;
}

.header-partial {
  display: flex;
  width: 33.33%;
}

.header-section__left {
  justify-content: start;
  align-self: center;
}

.header-section__middle {
  justify-content: center;
  align-items: center;
}

.header-section__right {
  justify-content: end;
  align-items: center;
}

.shop, .shop .header-icons__nav-item {
  background-color: #fff;
  color: #000;
  stroke: #000;
}

.shop .brand-logo {
  color: #000;
}

.shop {
  border-bottom: 1px solid #e0e0e0;
}