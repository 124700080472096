.section-header__text {
  font-size: 72px;
  text-align: center;
  margin: 20px 0;
}

.flyout__first-level {
  display: none;
  padding: 48px 0 36px 0;
  transition: opacity .4s ease;
  opacity: 0;
}

.header-section__nav-item:hover .flyout__first-level {
  display: flex;
  opacity: 1;
}

.header-section__nav-underline {
  position: absolute;
  left: 0;
  transition: all 0.2s ease-in-out;
  transform: translateX(50px) scaleX(0.4);
  border-bottom: 1px solid black;
  width: 88px;
  opacity: 0;
}