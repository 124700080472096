.cart__product-remove-button-icon, .cart-flyout__header-close-button, .search-bar__close-button-icon {
  height: 32px;
  width: 32px;
  border-radius: 18px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 6px;
}

.cart__product-remove-button-icon:hover, .cart-flyout__header-close-button:hover, .search-bar__close-button-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.cart-flyout__header-close-button {
  justify-content: center;
  display: flex;
  align-items: center;
}
.cart-flyout__header-close-button-icon {
  width: 16px;
  height: 16px;
}